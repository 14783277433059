.marginBottom {
    margin-bottom: 24px;
}

.scrollableBox {
    height: 10rem;
    overflow: 'scroll';
}

.addedProductBox {
    margin-top: 20px;
}

.itemBottom {
    margin-bottom: 20px !important;
}
